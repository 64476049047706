<!--
 * @Author: your name
 * @Date: 2021-08-17 09:33:39
 * @LastEditTime: 2021-09-16 15:46:42
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \全过程\prcoress\src\views\main\basicPage\system\menuMana.vue
-->
<template>
  <div class="equip-container">
    <div class="title">职位管理</div>
    <div class="query-nav">
      <el-form ref="queryFormRef" :inline="true" size="small" :model="typeForm" label-width="100px">
        <el-form-item prop="searchValue" label="职位名称">
          <el-input v-model.trim="typeForm.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-button type="primary" size="small" style="margin-right: 6px;" @click="handleSearch">搜索</el-button>
        <el-button @click="resetForm">重置</el-button>
      </el-form>
    </div>
    <div class="buts">
      <el-button type="primary" size="small" @click="openDialog">新建</el-button>

      <div>
        <el-button type="danger" :disabled="isSelect" @click="handleDelete(logIds)">批量删除</el-button>
      </div>
    </div>
    <div class="table">
      <el-table
        :data="equipmentList"
        v-loading="loading"
        size="medium"
        @selection-change="handleSelectionChange"
        stripe
      >
        <el-table-column type="selection" width="60" align="center" :resizable="false"></el-table-column>
        <el-table-column width="60" fixed type="index" label="序号" align="center" :resizable="false"></el-table-column>
        <el-table-column prop="name" label="职位名称" align="center" :resizable="false"></el-table-column>
        <el-table-column prop="code" label="职位编码" align="center" :resizable="false"></el-table-column>
        <el-table-column prop="sort" label="排序" align="center" :resizable="false"></el-table-column>
        <el-table-column prop="remark" label="备注" align="center" :resizable="false"></el-table-column>
        <el-table-column prop="orgName" label="操作" align="center" :resizable="false">
          <template slot-scope="{ row }">
            <div class="comm-operation">
              <span class="edit-btn mr-16" @click="handleCheck(row)">修改</span>
              <span class="delete-btn mr-16" @click="handleDelete(row.id)">删除</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页区域 -->
    <div class="page">
      <el-pagination
        :total="total"
        :current-page="typeForm.pageNo"
        :page-size="typeForm.pageSize"
        :page-sizes="[10, 20, 30, 50]"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </div>
    <!-- 新增/编辑 对话框 -->
    <el-dialog
      :title="titleName"
      :visible.sync="dialogVisible"
      width="560px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form ref="paramsFormRef" label-width="80px" :model="paramsForm" :rules="rules">
        <el-form-item label="职位名称" prop="name">
          <el-input v-model.trim="paramsForm.name" maxlength="50"></el-input>
        </el-form-item>
        <el-form-item label="唯一编码" prop="code">
          <el-input v-model.trim="paramsForm.code" maxlength="50"></el-input>
        </el-form-item>
        <el-form-item label="排序">
          <el-input v-model.trim="paramsForm.sort" maxlength="10"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            v-model="paramsForm.remark"
            type="textarea"
            maxlength="200"
            :rows="5"
            resize="none"
            show-word-limit
            placeholder="请输入"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onClose">取消</el-button>
        <el-button type="primary" :loading="submitLoading" @click="onSubmit">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getList, addOrEdit, postDelete, getEdit, postEdit } from '@/api/main/system/positionMana';

export default {
  data() {
    return {
      total: 0,
      typeForm: {
        pageNo: 1,
        pageSize: 10
      },
      typeForm: {
        name: '',
        pageNo: 1,
        pageSize: 10
      },
      paramsForm: {
        name: '',
        code: '',
        id: '',
        sort: '',
        remark: ''
      },
      rules: {
        name: [{ required: true, message: '请输入职位名称', trigger: 'blur' }],
        code: [{ required: true, message: '请输入唯一编码', trigger: 'blur' }]
      },
      equipmentList: [],
      logIds: [],
      isSelect: true,
      titleName: '新增职位',
      dialogVisible: false,
      submitLoading: false,
      loading: false,
      isAdd: true
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 获取菜单list
    getList() {
      this.loading = true;
      getList(this.typeForm)
        .then(res => {
          if (res.code !== 200) {
            return;
          }
          this.total = res.data.totalRows;
          this.equipmentList = res.data.rows;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 搜索
    handleSearch() {
      this.typeForm.pageNo = 1;
      this.getList();
    },
    handleSelectionChange(logIds) {
      this.logIds = [];
      if (logIds.length) {
        logIds.map(item => {
          this.logIds.push(item.id);
        });
      } else {
      }
      this.isSelect = !this.logIds.length;
    },
    // 分页
    handleSizeChange(val) {
      this.typeForm.pageSize = val;
      this.typeForm.pageNo = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.typeForm.pageNo = val;
      this.getList();
    },
    openDialog() {
      this.isAdd = true;
      this.dialogVisible = true;
    },
    onClose() {
      this.dialogVisible = false;
      this.$refs.paramsFormRef.resetFields();
      this.paramsForm = this.$options.data().paramsForm;
    },
    resetForm() {
      this.typeForm.pageNo = 1;
      this.typeForm.pageSize = 10;
      this.typeForm.name = '';
      this.getList();
    },
    onSubmit() {
      console.log(this.paramsForm);
      this.$refs.paramsFormRef.validate(valid => {
        if (!valid) return;
        this.submitLoading = true;
        if (this.isAdd) {
          addOrEdit(this.paramsForm)
            .then(res => {
              this.$message.success('新增职位成功！');
              this.onClose();
              this.getList();
            })
            .finally(() => {
              this.submitLoading = false;
            });
        } else {
          postEdit(this.paramsForm)
            .then(res => {
              this.$message.success('编辑职位成功！');
              this.onClose();
              this.getList();
            })
            .finally(() => {
              this.submitLoading = false;
            });
        }
      });
    },
    // 点击删除
    handleDelete(data) {
      let ids;
      let message = '';
      if (typeof data === 'string') {
        ids = data;
        message = '删除职位成功！';
      } else if (Array.isArray(data)) {
        ids = data.join(',');
        message = '批量删除职位成功！';
      }

      this.$confirm('是否确认删除所选职位, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return postDelete({ ids });
        })
        .then(res => {
          this.$message.success(message);
          this.getList();
        })
        .catch(err => {
          if (err) return; // axios 统一处理了错误提示
        });
    },
    handleCheck(row) {
      getEdit({ id: row.id }).then(res => {
        if (res.code !== 200) {
          return;
        }
        this.titleName = '编辑职位';
        this.dialogVisible = true;
        this.isAdd = false;
        this.paramsForm = res.data;
      });
    }
  }
};
</script>

<style lang="less" scoped>
.equip-container {
  .page {
    margin: 16px 0;
    float: right;
  }
}
.query-nav {
  border-bottom: 1px solid #dcdfe7;
  border-top: 1px solid #dcdfe7;
  padding-top: 24px;
}
.buts {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;

  span {
    font-size: 14px;
    font-weight: 400;
    color: #117bf7;
  }
}
.table {
  margin-top: 12px;
}
.operation {
  span {
    font-size: 14px;
    border-bottom: 1px solid #117bf7;
    color: #117bf7;
    margin: 0 8px;
    cursor: pointer;
  }
}
.title {
  padding: 0 16px 12px;
  font-size: 16px;
  font-weight: bold;
}
</style>
