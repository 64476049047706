/*
 * @Author: your name
 * @Date: 2021-09-07 12:05:46
 * @LastEditTime: 2021-09-07 16:20:25
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \全过程\prcoress\src\api\main\system\positionMana.js
 */
import { axios } from '@/utils/request';
/**
 * 分页查询菜单
 */
export function getList(parameter) {
  return axios({
    url: '/sysPos/page',
    method: 'get',
    params: parameter
  });
}

export function addOrEdit(parameter) {
  return axios({
    url: '/sysPos/add',
    method: 'post',
    data: parameter
  });
}
export function postDelete(parameter) {
  return axios({
    url: '/sysPos/delete',
    method: 'post',
    data: parameter
  });
}

export function getEdit(parameter) {
  return axios({
    url: '/sysPos/detail',
    method: 'get',
    params: parameter
  });
}

export function postEdit(parameter) {
  return axios({
    url: '/sysPos/edit',
    method: 'post',
    data: parameter
  });
}
